import {motion} from 'framer-motion'
import {graphql, useStaticQuery, Link} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import {Heading} from '..'
import Clouds from './clouds/clouds'
import styles from './header.module.css'

const container = {
  hidden: {opacity: 0},
  visible: {
    opacity: 1,
    transition: {
      when: `beforeChildren`,
      staggerChildren: 0.5,
      delay: 0.5,
    },
  },
}

const item = {
  hidden: {y: 20, opacity: 0},
  visible: {
    y: 0,
    opacity: 1,
  },
}

const registerLink = {
  title: `Register Now`,
  url: `/registration/`,
}

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "hero-balloon.png"}) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <motion.header className={styles.header}>
      <motion.div
        className={styles.balloonWrapper}
        style={{originX: 1}}
        animate={{rotate: -4}}
        transition={{
          yoyo: Infinity,
          duration: 2,
          ease: `easeInOut`,
        }}
      >
        <Img
          className={styles.balloon}
          fixed={data.file.childImageSharp.fixed}
          alt="Patch and friends in a hot air balloon"
          style={{position: `absolute`, right: `-12rem`}}
        />
      </motion.div>
      <motion.div
        className={styles.wrapper}
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <div className={styles.masthead}>
          <Heading className={styles.heading} variants={item} />
          <motion.div className={styles.cta} variants={item}>
            <p className={styles.text}>
              An epic learn-to-code adventure game that builds confidence,
              creativity, and compassion.
              <span>Fun for ages 7 and up.</span>
            </p>
            <p>
              <Link to={registerLink.url} className={styles.button}>
                {registerLink.title}
              </Link>
              <a
                href="https://play.secretofthesourcecode.com"
                className={styles.button}
              >
                Play Now
              </a>
            </p>
          </motion.div>
        </div>
      </motion.div>
      <Clouds />
    </motion.header>
  )
}
