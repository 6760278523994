import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import React, {useState} from 'react'
import {CharacterSlider} from '..'
import characters from '../../../../data/main-characters.json'
import styles from './main-characters.module.css'

export const MainCharacters = () => {
  const [index, setIndex] = useState(0)

  const nextIndex = () => {
    setIndex(current => (current + 1) % characters.length)
  }

  const prevIndex = () => {
    setIndex(current => (current - 1 + characters.length) % characters.length)
  }

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.sliderContainer}>
          <CharacterSlider index={index} characters={characters} />
          <div className={styles.controls}>
            <div className={styles.buttonGroup}>
              <motion.button
                className={styles.prev}
                onClick={prevIndex}
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
              >
                <img
                  className={styles.chevron}
                  src="/images/icons/chevron-left.svg"
                  alt=""
                />
              </motion.button>
              <motion.button
                className={styles.next}
                onClick={nextIndex}
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
              >
                <img
                  className={styles.chevron}
                  src="/images/icons/chevron-right.svg"
                  alt=""
                />
              </motion.button>
            </div>
            <div className={styles.characterDescription}>
              <p
                dangerouslySetInnerHTML={{
                  __html: characters[index].description,
                }}
              />
            </div>
          </div>
        </div>
        <header className={styles.header}>
          <h2 className={styles.heading}>Meet Patch and friends</h2>
          <p className={styles.description}>
            Meet Patch&hellip; a clever young bot who is studying to be a coder
            with the help of Grandbot Pop, and the other bots around Var Vista.
            <span>
              But trouble is brewing in town and it won&apos;t be long before
              Patch&apos;s summer vacation turns into an epic quest of mystery,
              danger, and discovery.
            </span>
            <span>
              Follow along as Patch learns how to use code to solve puzzles with
              math, music, art, logic, and more.
            </span>
          </p>
        </header>
      </div>
    </section>
  )
}
