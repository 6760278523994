import React from 'react'
import {Link} from 'gatsby'
import styles from './call-to-action.module.css'

const registerLink = {
  title: `Register Now`,
  url: `/registration/`,
}

export const CallToAction = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h2 className={styles.heading}>Registration is Now Open!!</h2>
        <p className={styles.description}>
          Boost your child’s enthusiasm for STEM learning,
          <br />
          play games, and build skills:
        </p>
        <Link to={registerLink.url} className={styles.button}>
          {registerLink.title}
        </Link>
        <a
          href="https://play.secretofthesourcecode.com"
          className={styles.button}
        >
          Play Now
        </a>
      </header>
      <div className={styles.logo}>
        <img src="/images/logo.svg" alt="Secret of the Source Code logo" />
      </div>
    </div>
  </section>
)
