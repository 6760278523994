import {graphql, Link, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './learning-experience.module.css'

export const LearningExperience = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: {eq: "fun-for-kids.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageTwo: file(relativePath: {eq: "inspiring-for-parents.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h2 className={styles.heading}>A Self-Guided Learning Experience</h2>
          {/* prettier-ignore */}
          <p className={styles.description}>
            Watch your child&rsquo;s confidence grow as they navigate our hero <strong>Patch</strong> through lessons
            and challenges that engage kids in learning about technology.
          </p>
        </header>
        <div className={styles.cards}>
          <div className={styles.card}>
            <Img
              fluid={data.imageOne.childImageSharp.fluid}
              className={styles.cardPicture}
              loading="lazy"
            />
            <h3 className={styles.cardTitle}>Engaging for kids</h3>
            <p className={styles.cardDescription}>
              Solve puzzles, debug code, win rewards and unlock new skills. Your
              kid will not even know they&rsquo;re learning.
            </p>
          </div>
          <div className={styles.card}>
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              className={styles.cardPicture}
              loading="lazy"
            />
            <h3 className={styles.cardTitle}>Inspiring for parents</h3>
            <p className={styles.cardDescription}>
              This game-based coding course is delivered in chapters, a format
              that keeps your kid engaged and learning through repetitions and
              leveling up.
            </p>
          </div>
        </div>
        <Link to="/curriculum" className={styles.button}>
          view the curriculum &rarr;
        </Link>
      </div>
      <div className={styles.clouds} />
    </section>
  )
}
