import React from 'react'
import {DefaultLayout, Footer} from '../components/common'
import {
  CallToAction,
  CodingExperience,
  Header,
  LearningExperience,
  MainCharacters,
  Testimonials,
} from '../components/home'

const Index = () => (
  <DefaultLayout>
    <Header />
    <LearningExperience />
    <MainCharacters />
    <CodingExperience />
    <Testimonials />
    <CallToAction />
    <Footer />
  </DefaultLayout>
)

export default Index
