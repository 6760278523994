import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import {motion} from 'framer-motion'
import React from 'react'
import testimonials from '../../../../data/testimonials.json'
import {Testimonial} from '../../../types'
import styles from './testimonials.module.css'

const renderTestimonials = (list: Testimonial[]) =>
  list.map(t => (
    <motion.div
      key={t.id}
      className={styles.testimonial}
      whileHover={{
        scale: 1.1,
        boxShadow: `0 1rem 1rem hsla(250, 33%, 22%, 0.25)`,
      }}
    >
      <div className={styles.quote}>{t.quote}</div>
      <div className={styles.author}>
        <div className={styles.details}>
          <div className={styles.name}>{t.author.name}</div>
          <small className={styles.qualification}>
            {t.author.qualification}
          </small>
        </div>
      </div>
    </motion.div>
  ))

export const Testimonials = () => (
  <section className={styles.section}>
    <header className={styles.header}>
      <h2 className={styles.heading}>
        Brought to You by <span className={styles.lrfheading}>Little Robot Friends</span>
      </h2>
      <p className={styles.description}>
        We teach kids about code and electronics. Here are what people have said
        about us:
      </p>
    </header>
    <div>
      <Carousel
        slides={renderTestimonials(testimonials)}
        infinite
        centered
        dots
        slidesPerPage={5}
        clickToChange
        autoPlay={5000}
        animationSpeed={1000}
        stopAutoPlayOnHover
        breakpoints={{
          640: {
            slidesPerPage: 1,
            offset: 0,
          },
          900: {
            slidesPerPage: 2,
            offset: 0,
          },
          1200: {
            slidesPerPage: 3,
            offset: 0,
          },
          2000: {
            slidesPerPage: 4,
            offset: 0,
          },
        }}
      />
    </div>
  </section>
)
