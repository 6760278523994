import React from 'react'
import styles from './clouds.module.css'

export const Clouds = (props: unknown) => (
  <svg viewBox="0 0 1920 850" {...props} className={styles.clouds}>
    <g>
      <g className={styles.cloudTwo}>
        <path
          className={styles.shadow}
          d="M600.59 191.2c-3.72 1.76-4.64 9.18-2.94 14.68a16.77 16.77 0 004.28 7c2.84 2.74 5.8 3.6 9.63 4.71 7.6 2.2 17.38 2.6 19.36 1.92.11 0 .53-.19.74 0 .41.38-.5 1.72-.83 3.09a7.51 7.51 0 00.87 4.88c.55 1.25 1.9 3.69 5.94 6.08 1.79 1.06 5.34 2.84 15.11 3.73 6.71.61 10.07.92 14.74.19a43.13 43.13 0 0015.94-5.85c4-2.61 7.16-4.64 7.92-5.9.07-.12.38-.65.79-.65s.73.71 1.19 1.43a19.25 19.25 0 002 2.12c3.12 3.09 9.31 5.16 9.31 5.16a44.59 44.59 0 0012.9 2.07 50.44 50.44 0 0015.89-2.12 49.69 49.69 0 0013.26-6.44 58.48 58.48 0 0012.21-10.83 55.92 55.92 0 005.66-8 27.9 27.9 0 002.49-4.74 5.48 5.48 0 01.78-1.66c.24-.34.33-.39.37-.41.8-.4 2.46 1.38 2.72 1.65 2 2.1 5.76 2.81 10.64 3.73a44 44 0 0014.19.14 53 53 0 0012.89-2.85 34.77 34.77 0 009.22-4.56 28.43 28.43 0 007.23-7.28c2-3.05 1.31-3.39 3.54-6.45 1.85-2.52 3.2-3.45 4.52-6.31a19.31 19.31 0 001.24-3.68 19.64 19.64 0 00.23-7.56 19 19 0 00-2.85-7.32 22.51 22.51 0 00-1.8-2.31c-5.1-5.61-17.14-10.19-27.9-17-4.42-2.81-6.67-4.75-10.11-4.36-1 .12-3.53 1.4-8.53 4-2.47 1.27-5.09 2.24-7.53 3.57-2.06 1.11-3.75 2.16-5 1.48a3.05 3.05 0 01-1.19-2.18s-.73-3.88-9.71-11.7c-12.33-10.72-94.85 32.16-98.14 42.73-1 3.11-2.23 6.15-3 9.32-.47 2-.88 4.18-1.39 4.16s-.42-2.39-1.58-4.86-2.94-3.71-5-5.15-7.36-5.28-14.27-4.56a18.36 18.36 0 00-4.66 1.19c-14 5.17-18.63 16.21-27.66 15.66-1.48-.16-2.42-.57-3.71.04z"
        />
        <path
          className={styles.light}
          d="M654.67 183.77a58.83 58.83 0 016.67-19.47c7.31-13.1 17.71-19.46 30.41-27.21 14.57-8.9 25.89-12.08 39-15.74 12.4-3.48 18.15-3.73 23.74-1.33 8.61 3.69 12.32 11.72 12.8 12.8a26.53 26.53 0 011.34 16.81c-1.88 8.22-7 12.9-6.14 13.6s7.14-5 10.14-7.73c8.9-8.21 10.09-10.87 15.47-14.14 3-1.8 6.57-4 11.47-4.54 11.29-1.24 21.78 7.13 26.94 15.47a30.55 30.55 0 01-3.2 37.08 33.09 33.09 0 01-17.87 10.41c-10.86 2.19-20-2-23.74-3.74-9.27-4.26-15.16-10.82-17.34-9.07-.69.56-.59 1.61-.54 3.2.17 4.73-.73 9.43-1.06 14.14-.29 4.05-3.55 9.76-17.07 16.81a39.79 39.79 0 01-17.34 7.2c-6.38 1-12.37 1.87-16.54-1.6-1.23-1-3.84-3.7-9.07-9.07a4.78 4.78 0 00-2.94-1.6 5.15 5.15 0 00-3.47 1.33c-2.52 2-5.45 3.4-7.72 5.64a47.76 47.76 0 01-12.76 9c-7.34 3.28-14.43 2.27-17.19 1.88a34.68 34.68 0 01-14.5-5.64c-2.14-1.46-6.67-4.55-7-8.06-.25-2.8-.41-4.5.14-5.77a1.82 1.82 0 00.14-1.24c-.17-.56-.68-.92-1.64-1.4a10.39 10.39 0 00-2.65-1.09c-4.6-1.06-13.72-1-20.36-4.53a26.51 26.51 0 01-6.56-5.15 21.75 21.75 0 01-4-5.46c-1.07-2.11-1.29-3.47-.93-4.68a4.25 4.25 0 01.86-1.56c4.67-5.75 12.48-9.68 12.48-9.68 2.49-1.25 4.35-2 6.4-2.81 5.1-2 7.71-3 10.84-3 1.95 0 3.66.49 7.1 1.48.63.18 7.24 2.11 10.3 4.45.21.16 1.55 1.2 3.44 2.57l.85.63"
        />
      </g>
      <g className={styles.cloudOne}>
        <path
          className={styles.shadow}
          d="M114.86 130.07c-.47 2.57-2.91 17.36 6.66 28.2 5.14 5.82 11.38 7.82 15.54 9.11 14.65 4.5 27.59-.19 41.75-5.33 7.55-2.75 14.24-6.78 15.54-8.22a3.48 3.48 0 011-.78c1.77-.87 3.89.14 4.4.36 4.58 2 19.5.36 30.25-2.84.78-.23-.16 0 8.9-3.3 13.74-5.06 17.55-6.27 21.27-10.27a26.88 26.88 0 004.4-6.5 17.65 17.65 0 002.2-7.8 16.84 16.84 0 00-1-5.41c-3.45-9.12-35.23-11.12-45.22-28.05-4.62-7.82-8.22-25-13.46-29.83-.22-.21-1.51-1.37-3.09-3.1a53.28 53.28 0 01-5.28-6.73c-4.07-5.9-19.34-6.67-20.74-6.73-20.83-.89-50.44 6.27-55.3 5.09a25.34 25.34 0 00-2.75-.52 28.45 28.45 0 00-4.76-.25c-5.53.15-8.79.24-12.46 1.47a27.24 27.24 0 00-9.17 5.49 40.62 40.62 0 00-13.3 19.31c-3.79 12.33 0 22.8 2.07 28.59a54.36 54.36 0 0010.27 17.1c3.2 3.61 6.88 7.66 13.07 9.53a23.43 23.43 0 006.6 1"
        />
        <path
          className={styles.light}
          d="M266.48 120.35a42.88 42.88 0 00-5.14-12c-3.77-6.18-5.65-9.27-8.33-12a42.55 42.55 0 00-12-8.7c-1-.46-8.05-3.82-11.12-3.79a19.78 19.78 0 01-3.86-.91.87.87 0 01-.56-.35c-.36-.6.57-1.55 1.05-2.59.55-1.22.38-2.32.08-4.16a17.42 17.42 0 00-2.56-6.29c-1.56-2.74-2.64-4.63-4.89-6.46a17.67 17.67 0 00-4.59-2.64 15.32 15.32 0 00-5.71-1.29 14.13 14.13 0 00-8 2.77c-4 2.76-7.17-.36-11.29 2.5a3.18 3.18 0 01-3.74.06c-2.38-.8-3.17.45-1.44-1.43 2.32-2.51-.19-.42 3.86-2.92 7.67-4.74 11.67-5 12.16-7.43.18-.91-.15-1.92-3.93-5.9-5.24-5.51-7.86-8.27-12.17-10.73a31.83 31.83 0 00-13.69-4.75c-6.27-.54-13.41.93-17 3.31a8.8 8.8 0 01-2.33 1.17 11.11 11.11 0 01-7-.36c-5-1.56-8.7 3.43-22.63 8.32-2.73 1-5 1.75-6.86 2.37a14.74 14.74 0 00-1 4.79c0 4.22 1.65 6.51.46 7.7s-1.72 1.13-1.72 1.13l-2.89-5.8.19-6.34a12.57 12.57 0 01-2.37.35 15.14 15.14 0 00-8.32 2.24 12.55 12.55 0 00-2.83 2.64c-4.39 5.41-5 13.14-5.49 20.57-.3 4.5-.45 6.75 0 9.79.52 3.47 1.25 4.49 2.35 10.19.83 4.32.57 4.52 1.18 6.66a26 26 0 004.89 9.21c5.07 6.26 11.8 8.39 16.85 10 7.33 2.32 11 3.48 14.89 2.35.86-.26 2.9-1 3.91 0s.34 3.55 0 4.7a56.94 56.94 0 00-2 15.67c.23 7.66 6.69 13.27 7.64 14.1 7.15 6.22 16.29 5.47 25.66 4.7 4.56-.37 15-1.36 25.07-8.62a32.49 32.49 0 006.47-5.68 2.06 2.06 0 01.82-.64c1.51-.6 3.43 1.12 4.16 1.67 3.74 2.75 10.91.17 16.88-2 5.74-2.07 5.24-3 10.52-4.7s6-.85 12.19-2.34c5.93-1.42 3.95-1.91 18.77-8.25 1.75-.75 4.29-1.82 5.37-4.32.8-1.79.56-3.55.04-5.57z"
        />
      </g>
      <g className={styles.cloudThree}>
        <path
          className={styles.shadow}
          d="M1085.92 310.7c-2.9 3.9-8 12-8 22.42a25.38 25.38 0 001.59 9.53 26.17 26.17 0 0011 12.71c11.9 7.23 25.35 2.3 27.18 1.59 9.65-3.7 16-11.71 18.36-14.65.78-1 5.83-7.41 5.83-7.41 1.28-.35 2.76-.64 3-.18s-.37.85-.63 1.27c-1.31 2.14 1.52 6.75 4.24 9.44a19.72 19.72 0 0010.72 5.2c10 1.76 17.93-3.54 20.42-5.2 4.91-3.26 8.15-7.46 8.6-8.92a17.31 17.31 0 011.54-3.41c0-.07.13-.18.28-.43s.19-.33.24-.34c.24 0 .57 1.29.57 1.29.49 1.89 3.65 4.36 4.88 5.14 4.5 2.82 12.49 1.3 18.11-2.12 2-1.19 6.15-3.75 8.15-8.67 1.68-4.12.61-6.95-1.86-17.27-4.53-18.93-4.77-25.39-11.3-30.89a11.76 11.76 0 00-3.4-2.37c-4.21-1.69-8.4.4-9 .7a11.71 11.71 0 00-4.62 4.18c-.64 1.05-1.15 2.31-1.35 2.24s.06-1.11.19-2.11a18.51 18.51 0 00-.38-5.46 17.78 17.78 0 00-6.75-10.28c-6.64-4.76-14.45-2.65-16.37-2.05-6.32 2-11.4 7.17-12.65 11.24-.18.59-.77 2.75-1.22 2.69s-.41-1.29-.51-2.05c-.38-2.9-1.91-5-3.4-7.06a20.22 20.22 0 00-11.75-7.58c-6.35-1.35-11.59 1.06-16.7 3.4-.91.42 0 .07-10.79 6.36a31.89 31.89 0 00-5 3.47c-2.77 2.34-2.62 3.05-4.18 4.23-4 3.06-7.34.08-12.65 2.57a15.36 15.36 0 00-6.42 6.17c-.66 1.07-3.6 5.82-2.5 11.55a19.51 19.51 0 003.66 7.71c.55.74 1 1.33 1.41 1.73"
        />
        <path
          className={styles.light}
          d="M1086.76 282.67c3.53-2.74 6.12-4.74 10-5.2a12.5 12.5 0 015.68.47c3.61 1.24 5.1 3.85 11.11 7.69 1 .63 2.33 1.44 3.07.94a2.18 2.18 0 00.36-2.48c-.67-1.45-2.51-1.21-2.84-2.37a9 9 0 00-1.2-2.31c-.53-1-.06-.63-1.76-1.78s-2.54-4.41-2.48-5.83c.24-5.12 11.88-9.39 21-9.93a34.81 34.81 0 0114.66 2.95c1.33.6 5.13 2.34 5 4.14 0 .2-.07.3-.94 1.54-1.26 1.79-1.9 2.65-2.49 3.54s-.88 1.41-1.3 2.13c-1.4 2.43-3.37 5.82-2.83 6.26s2.35-2.67 6.85-4.61c2.28-1 3.53-.93 5.08-2.36a9.79 9.79 0 002.37-3.9c2.67-5.92 11.52-7.73 13.24-8 2.33-.42 7.25-1.3 12 1.42 3.15 1.78 4.75 4.26 6.5 7 2.24 3.47 2 4.88 1.9 5.32-.36 1.53-1.47 1.82-1.78 3.79a4.6 4.6 0 00.71 3.54A4.14 4.14 0 001191 286c2.26.46 4-1.73 7.09-4s5.58-4.14 8.51-4.37c5.48-.43 10.59 4.78 11.35 5.55a40.15 40.15 0 004.61 4.49c2 1.6 2.22 1.44 2.83 2.25 2.34 3.11.61 8.15.24 9.22-1.28 3.74-2.78 3.64-3.55 6.86-.46 1.93-.07 2.59.48 7.56.62 5.65.93 8.47.35 11a16.2 16.2 0 01-4.49 7.8l-7.09 3.31a23.34 23.34 0 01-8.75-3.19 18.56 18.56 0 01-5.2-4.37c-2.48-3.11-2.48-5.43-3.9-5.56s-3.19 2.24-3.66 4.38c-.4 1.78.27 2.44 0 4.13-.34 2-1.69 3.44-3.43 5-4.37 3.83-7.46 6.54-12.89 8.63a36.44 36.44 0 01-14.3 2.36c-6.22-1.94-8.34-4.48-9.1-6.5a37.26 37.26 0 00-2.25-5.44c-.86-1.68-1.47-2.51-2.48-3.07a4.82 4.82 0 00-3.43-.47c-1.83.51-2.77 2.29-3.3 3.19-1 1.63-3.29 3.6-7.92 7.56a36.08 36.08 0 01-10.64 6.86 31.84 31.84 0 01-9.58 2.13c-3.08.19-7.11.45-11.7-1.54a19.18 19.18 0 01-11.8-16.87c-.1-1.57-.54-8.2 3.79-13.12a19.26 19.26 0 015.43-4.13c.43-.21.74-.35 5.08-2 0 0-.78-1.57-1.06-2-1-1.62-3.58-1.5-5.32-1.89-3.49-.79-5.5-4.09-7.56-7.45s-2.49-6.2-3.31-11.82a8.27 8.27 0 01.59-5.55 7.26 7.26 0 012.12-2.27z"
        />
      </g>
      <g className={styles.cloudTwo}>
        <path
          className={styles.shadow}
          d="M1577.4 174.6c-2.9-3.9-8-12-8-22.42a25.44 25.44 0 011.59-9.53 26.17 26.17 0 0111-12.71c11.9-7.23 25.35-2.29 27.18-1.59 9.65 3.7 16 11.71 18.36 14.65.78 1 5.83 7.42 5.83 7.42 1.28.34 2.76.64 3 .17s-.37-.84-.63-1.27c-1.31-2.14 1.52-6.75 4.24-9.44a19.8 19.8 0 0110.72-5.2c10-1.76 17.93 3.55 20.42 5.2 4.91 3.26 8.15 7.46 8.6 8.92a17 17 0 001.54 3.41c0 .07.13.18.28.43s.19.33.24.34c.24 0 .57-1.29.57-1.29.49-1.89 3.65-4.36 4.88-5.13 4.5-2.82 12.49-1.31 18.11 2.12 2 1.18 6.15 3.74 8.15 8.66 1.68 4.12.61 6.95-1.86 17.28-4.53 18.92-4.77 25.39-11.3 30.88a12 12 0 01-3.4 2.38c-4.21 1.69-8.4-.41-9-.71a11.74 11.74 0 01-4.62-4.17c-.64-1.05-1.15-2.32-1.35-2.25s.07 1.11.19 2.12a18.44 18.44 0 01-.38 5.45 17.78 17.78 0 01-6.76 10.28c-6.64 4.77-14.45 2.65-16.37 2.05-6.32-2-11.4-7.16-12.65-11.23-.18-.6-.77-2.75-1.22-2.7s-.41 1.29-.51 2.05c-.38 2.91-1.91 5-3.4 7.07a20.26 20.26 0 01-11.75 7.57c-6.35 1.35-11.59-1-16.7-3.4-.91-.42-.05-.07-10.79-6.36a32.4 32.4 0 01-5-3.46c-2.77-2.35-2.62-3.06-4.18-4.24-4-3.06-7.34-.08-12.65-2.57a15.41 15.41 0 01-6.42-6.16c-.66-1.08-3.6-5.82-2.5-11.56a19.51 19.51 0 013.66-7.71c.55-.74 1-1.33 1.41-1.73"
        />
        <path
          className={styles.light}
          d="M1578.24 195.84c3.53 2.73 6.12 4.74 10 5.2a12.39 12.39 0 005.68-.48c3.61-1.23 5.1-3.85 11.11-7.68 1-.63 2.33-1.44 3.07-.94a2.18 2.18 0 01.36 2.48c-.67 1.45-2.51 1.21-2.84 2.36a8.9 8.9 0 01-1.2 2.32c-.53 1-.06.63-1.76 1.77s-2.54 4.41-2.48 5.84c.24 5.11 11.88 9.39 21 9.93a34.73 34.73 0 0014.66-3c1.33-.59 5.13-2.33 5-4.13 0-.2-.07-.3-.94-1.54-1.26-1.79-1.9-2.66-2.49-3.55s-.88-1.4-1.3-2.12c-1.4-2.43-3.37-5.83-2.83-6.27s2.35 2.68 6.85 4.61c2.28 1 3.53.93 5.08 2.37a9.72 9.72 0 012.37 3.9c2.67 5.91 11.52 7.73 13.24 8 2.33.42 7.25 1.31 12.05-1.41 3.15-1.79 4.75-4.27 6.5-7 2.24-3.46 2-4.87 1.9-5.32-.36-1.52-1.47-1.81-1.78-3.78a4.59 4.59 0 01.71-3.54 4.14 4.14 0 012.25-1.42c2.26-.47 4 1.72 7.09 4s5.58 4.14 8.51 4.37c5.48.43 10.59-4.78 11.35-5.56a41 41 0 014.61-4.49c2-1.59 2.22-1.43 2.83-2.24 2.34-3.12.61-8.15.24-9.22-1.28-3.74-2.78-3.64-3.55-6.86-.46-1.93-.07-2.6.48-7.56.62-5.65.93-8.47.35-11a16.24 16.24 0 00-4.49-7.8l-7.09-3.31a23.39 23.39 0 00-8.75 3.2 18.56 18.56 0 00-5.2 4.37c-2.48 3.11-2.48 5.43-3.9 5.55s-3.19-2.23-3.66-4.37c-.4-1.78.27-2.45 0-4.14-.34-2-1.69-3.43-3.43-5-4.37-3.83-7.46-6.55-12.89-8.63a36.26 36.26 0 00-14.3-2.36c-6.22 1.94-8.34 4.48-9.1 6.5a36.85 36.85 0 01-2.25 5.43c-.86 1.68-1.47 2.52-2.48 3.08a4.82 4.82 0 01-3.43.47c-1.83-.52-2.77-2.3-3.3-3.19-1-1.63-3.29-3.61-7.92-7.57a36.27 36.27 0 00-10.64-6.85 31.84 31.84 0 00-9.58-2.13 23.59 23.59 0 00-11.7 1.54 19.18 19.18 0 00-11.82 16.78c-.1 1.58-.54 8.2 3.79 13.12a19.17 19.17 0 005.43 4.14c.43.21.74.35 5.08 2 0 0-.78 1.56-1.06 2-1 1.61-3.58 1.5-5.32 1.89-3.49.79-5.5 4.08-7.56 7.45s-2.49 6.2-3.31 11.82a8.27 8.27 0 00.59 5.55 7.26 7.26 0 002.17 2.42z"
        />
      </g>
      <g className={styles.cloudOne}>
        <path
          className={styles.shadow}
          d="M1704.57 684.92c3.72-1.75 4.64-9.18 2.93-14.67a16.5 16.5 0 00-4.28-7c-2.83-2.74-5.8-3.59-9.62-4.7-7.61-2.2-17.38-2.6-19.37-1.93-.1 0-.52.19-.73 0-.42-.38.5-1.72.83-3.08a7.55 7.55 0 00-.88-4.89c-.54-1.25-1.89-3.68-5.94-6.08-1.79-1.05-5.33-2.83-15.11-3.73-6.7-.61-10.06-.92-14.74-.18a42.89 42.89 0 00-15.93 5.85c-4 2.61-7.16 4.63-7.93 5.89-.07.12-.37.65-.78.65s-.74-.71-1.2-1.43a17.73 17.73 0 00-2-2.12c-3.11-3.09-9.3-5.16-9.3-5.16a45.06 45.06 0 00-12.9-2.07 50.7 50.7 0 00-15.89 2.12 50.05 50.05 0 00-13.27 6.45 58.06 58.06 0 00-12.2 10.82 57 57 0 00-5.67 8 28.24 28.24 0 00-2.48 4.75 6.16 6.16 0 01-.79 1.65c-.23.34-.33.4-.37.42-.79.4-2.46-1.39-2.71-1.66-2-2.09-5.77-2.81-10.64-3.73a44.3 44.3 0 00-14.19-.14 52.66 52.66 0 00-12.9 2.86 34.12 34.12 0 00-9.21 4.56 28 28 0 00-7.23 7.27c-2 3.06-1.31 3.4-3.55 6.45-1.84 2.53-3.19 3.46-4.51 6.31a19.27 19.27 0 00-1.25 3.69 19.59 19.59 0 00-.23 7.55 19.27 19.27 0 002.86 7.33 20.34 20.34 0 001.8 2.3c5.1 5.61 17.13 10.19 27.9 17 4.42 2.81 6.67 4.75 10.11 4.36 1-.11 3.52-1.4 8.52-4 2.47-1.27 5.09-2.25 7.54-3.57 2.06-1.12 3.74-2.16 5-1.49a3 3 0 011.19 2.18s.73 3.88 9.72 11.7c12.32 10.72 94.84-32.16 98.14-42.73 1-3.11 2.22-6.14 3-9.32.48-2 .89-4.17 1.39-4.16s.42 2.4 1.59 4.86 2.94 3.71 5 5.15 7.37 5.28 14.28 4.56a18 18 0 004.66-1.19c14-5.17 18.63-16.21 27.66-15.66 1.45.18 2.39.57 3.68-.04z"
        />
        <path
          className={styles.light}
          d="M1657.28 678.76a58.83 58.83 0 01-6.67 19.47c-7.31 13.1-17.71 19.46-30.41 27.21-14.57 8.9-25.89 12.08-38.94 15.74-12.4 3.48-18.16 3.73-23.75 1.34-8.61-3.69-12.32-11.73-12.8-12.81a26.4 26.4 0 01-1.33-16.8c1.88-8.23 6.94-12.91 6.13-13.61s-7.14 5-10.14 7.74c-8.9 8.2-10.09 10.86-15.47 14.13-3 1.8-6.57 4-11.47 4.54-11.29 1.24-21.78-7.13-26.94-15.47a30.59 30.59 0 013.2-37.08 33.09 33.09 0 0117.87-10.41c10.86-2.19 20 2 23.74 3.74 9.27 4.26 15.17 10.82 17.34 9.07.69-.56.59-1.61.54-3.2-.17-4.73.73-9.43 1.06-14.14.29-4 3.55-9.76 17.08-16.81a39.69 39.69 0 0117.34-7.2c6.37-1 12.37-1.86 16.54 1.6 1.22 1 3.83 3.7 9.07 9.07a4.71 4.71 0 002.93 1.6 5.13 5.13 0 003.47-1.33c2.52-1.95 5.45-3.4 7.72-5.64a47.76 47.76 0 0112.76-9c7.34-3.28 14.43-2.27 17.19-1.88a34.81 34.81 0 0114.51 5.64c2.14 1.46 6.66 4.56 7 8.06.25 2.8.41 4.5-.13 5.77a1.82 1.82 0 00-.15 1.24c.17.57.68.93 1.64 1.4a11 11 0 002.65 1.1c4.6 1 13.72 1 20.37 4.52a26.43 26.43 0 016.55 5.15 21.75 21.75 0 014 5.46c1.07 2.11 1.29 3.47.93 4.68a4.58 4.58 0 01-.85 1.57c-4.68 5.74-12.49 9.67-12.49 9.67-2.49 1.25-4.35 2-6.4 2.81-5.09 2-7.71 3-10.84 3-1.94 0-3.66-.49-7.1-1.48-.62-.18-7.24-2.11-10.3-4.45-.21-.16-1.55-1.2-3.43-2.57l-.86-.63"
        />
      </g>
      <g className={styles.cloudThree}>
        <path
          className={styles.shadow}
          d="M374.13 557.17c3.72-1.75 4.64-9.17 2.93-14.67a16.56 16.56 0 00-4.27-7c-2.84-2.74-5.8-3.59-9.63-4.7-7.6-2.2-17.38-2.6-19.36-1.93-.11 0-.53.19-.74 0-.41-.38.5-1.72.83-3.08a7.55 7.55 0 00-.87-4.89c-.55-1.25-1.9-3.68-5.95-6.08-1.78-1.05-5.33-2.83-15.1-3.73-6.71-.61-10.07-.92-14.74-.18a43 43 0 00-15.94 5.85c-4 2.61-7.16 4.63-7.92 5.89-.07.12-.38.65-.79.65s-.73-.71-1.19-1.43a19.25 19.25 0 00-2-2.12c-3.12-3.09-9.31-5.16-9.31-5.16a45 45 0 00-12.9-2.07 50.75 50.75 0 00-15.89 2.12 50 50 0 00-13.26 6.45A58 58 0 00215.84 532a56.49 56.49 0 00-5.66 8 27.82 27.82 0 00-2.49 4.75 5.61 5.61 0 01-.78 1.65c-.24.34-.33.4-.37.42-.8.4-2.46-1.39-2.72-1.66-2-2.09-5.77-2.81-10.64-3.73a44.6 44.6 0 00-14.19-.14 52.7 52.7 0 00-12.89 2.86 34.17 34.17 0 00-9.22 4.56 28.18 28.18 0 00-7.23 7.27c-2 3.06-1.31 3.4-3.55 6.45-1.84 2.53-3.19 3.46-4.51 6.31a19.58 19.58 0 00-1.47 11.24 19.06 19.06 0 002.85 7.33 21.81 21.81 0 001.8 2.3c5.1 5.61 17.14 10.19 27.9 17 4.42 2.81 6.67 4.75 10.11 4.36 1-.11 3.53-1.4 8.53-4 2.47-1.27 5.09-2.25 7.53-3.57 2.06-1.12 3.75-2.16 5-1.49a3.09 3.09 0 011.19 2.18s.73 3.89 9.71 11.7c12.33 10.72 94.85-32.16 98.14-42.73 1-3.11 2.23-6.14 3-9.31.47-2 .88-4.18 1.39-4.17s.42 2.4 1.58 4.86 2.94 3.71 5 5.15 7.36 5.28 14.27 4.56a17.93 17.93 0 004.66-1.19c14-5.17 18.63-16.21 27.66-15.66 1.46.09 2.4.48 3.69-.13z"
        />
        <path
          className={styles.light}
          d="M326.85 551a58.83 58.83 0 01-6.67 19.47c-7.31 13.1-17.72 19.46-30.41 27.21-14.57 8.9-25.9 12.08-38.95 15.74-12.4 3.48-18.15 3.73-23.74 1.34-8.62-3.69-12.33-11.73-12.81-12.81a26.47 26.47 0 01-1.33-16.8c1.88-8.23 6.95-12.91 6.13-13.61s-7.14 5-10.13 7.74c-8.9 8.2-10.09 10.86-15.47 14.14-3 1.79-6.57 4-11.48 4.53-11.29 1.25-21.78-7.13-26.94-15.47a30.59 30.59 0 013.2-37.08 33.09 33.09 0 0117.88-10.4c10.85-2.19 20 2 23.74 3.74 9.26 4.26 15.16 10.82 17.34 9.07.69-.56.59-1.61.53-3.2-.17-4.73.74-9.43 1.07-14.14.28-4 3.54-9.76 17.07-16.81a39.79 39.79 0 0117.34-7.2c6.38-1 12.37-1.86 16.54 1.6 1.22 1 3.84 3.7 9.07 9.07a4.78 4.78 0 002.93 1.6 5.13 5.13 0 003.47-1.33c2.52-1.95 5.45-3.4 7.72-5.64a47.76 47.76 0 0112.76-9c7.34-3.28 14.43-2.27 17.19-1.88a34.81 34.81 0 0114.51 5.64c2.14 1.46 6.67 4.56 7 8.06.26 2.8.41 4.51-.13 5.77a1.88 1.88 0 00-.15 1.24c.17.57.69.93 1.64 1.4a11 11 0 002.65 1.1c4.61 1 13.72 1 20.37 4.52a26.71 26.71 0 016.55 5.15 21.51 21.51 0 014 5.46c1.07 2.12 1.3 3.47.94 4.69a4.5 4.5 0 01-.86 1.56c-4.68 5.74-12.48 9.67-12.48 9.67-2.5 1.26-4.35 2-6.4 2.81-5.1 2-7.71 3-10.85 3-1.94 0-3.66-.49-7.1-1.48-.62-.18-7.23-2.11-10.3-4.45-.2-.16-1.55-1.19-3.43-2.57l-.86-.63"
        />
      </g>
      <g className={styles.cloudOne}>
        <path
          className={styles.shadow}
          d="M796.27 696.22c-7.17 2.57-14.85 5.4-23.08 12.31-5.36 4.5-6.49 7-6.93 8.08-2.39 6-.11 11.82.39 13.08a20.5 20.5 0 008.46 9.81 24.18 24.18 0 0014.62 3.5c7.17-.36 13.17-3.44 14.43-5 .09-.11.63-.8.83-.7s-.25 1.5-.4 2c-.92 3.49 3.63 7.9 4.57 8.81a20.76 20.76 0 0010.54 5.36c5.53 1 9.89-.44 14.09-1.89 5.65-2 9.19-4.5 14.88-8.58a74.92 74.92 0 0013.22-11.89 37.24 37.24 0 004.88-7.39 14.8 14.8 0 001.57-3.94c.41-2.2 0-4.12.87-4.48.26-.12.69-.1.78.07s-.28.66-.39.79c-.72.93.4 4.33 4.17 8.58 2.33 2.62 4.09 4.59 7.16 6.06 1.82.86 4.56 1.77 10.39 1.57a59.07 59.07 0 0020.39-4.72 73.63 73.63 0 0014.48-7.95A76.72 76.72 0 00942 704.52c2.45-3 7-8.92 8.5-15.19a17.79 17.79 0 00.47-5.58 16.53 16.53 0 00-2.05-6.77c-1.44-2.52-3.67-2.86-3.54-4.25.11-1.11 1.46-1.78 2.05-2.13 3.89-2.29 4.81-8.45 5.58-13.69.51-3.38 1.66-11.1-2.51-19-1.19-2.27-3.42-6.5-8.19-9.21s-9.06-2.29-15.35-1.81a56.49 56.49 0 00-22.19 6.54c-2.25 1.2-5.07 3.21-10.7 7.24a113.61 113.61 0 00-14.48 12.43c-2.38 2.55-3.58 3.87-4.25 6.06-.4 1.3-.69 3.13-1.26 3.15-.39 0-.69-.79-.79-1-1-2.44-4.67-4.17-7.16-5.35a33.13 33.13 0 00-19.35-1.96 36.1 36.1 0 00-20.39 11.88 54.42 54.42 0 00-4.32 6.3c-.72 1.22-1.41 2.64-2.76 5.43-2.52 5.19-3.78 7.78-4.15 9.17a68.24 68.24 0 00-1.52 7.57 17.4 17.4 0 01-.61 3c-.1 0 .19-1.14-.3-2-.83-1.36-3.49-1.17-4.74-1.06-8.57.8-11.72 1.93-11.72 1.93z"
        />
        <path
          className={styles.light}
          d="M951.39 641.32a29 29 0 00-5-8.4 19 19 0 00-5.78-5c-1.73-.91-4.12-1.74-8.77-1.8a48 48 0 00-15.66 2.67 65.11 65.11 0 00-14 6.67c-2.41 2-9.64 8.22-12.17 15-3.05 8.19-4.38 15.43-5.52 20.2s-2.29 10.1-2.29 10.1-1.14 2.48-5.34-1-3-14.87-3-14.87l-.68-4.42a9.63 9.63 0 01-1.4-2 31.28 31.28 0 00-8.33-3.67 33.52 33.52 0 00-6.34-1.06 35.5 35.5 0 00-16.29 1.87c-6.64 2.57-10.7 6.88-14 10.38a84.43 84.43 0 00-5.47 6.78 94.93 94.93 0 00-7.61 11.23c-1.09 2-1.93 3.85-1.93 3.85-.12.28-.24.53-.35.77.1 3.32.53 7.34 1.88 9.93 2.64 5.06-5.28 2.64-5.28 2.64l-4.62-4.4v-1.31a27.77 27.77 0 00-4.34.58c-5.14 1.74-12.51 3.75-17.1 6.77-.3.19-2.78 1.61-4.74 2.93-2.36 1.59-2.95 2.82-5.46 4.16a9.13 9.13 0 00-3.1 3.49 5.6 5.6 0 00-.42 1.19c-.46 2.08 1.27 8.09 7.72 14a36 36 0 009.82 6.42c2.35 1 12.46 5.35 22.79 1.31 1.66-.66 3.08-.83 3.93-2 .18-.24.41-.62.61-.57.46.11.22 2.42.19 3.17-.21 4.5 4.31 9.34 7.64 11.69a20.07 20.07 0 0011.26 3.48c14.25-.19 24.54-17.73 27.1-23.56 1.56-3.55 5.38-14.44 5.47-15.92a2.37 2.37 0 01.25-.93 2.94 2.94 0 011.5-1.24c1.85-.91 3.12-2.65 4.72-4.85l.44-.63a11.8 11.8 0 003.48 2.93 11.61 11.61 0 005 1.43h.8a36.6 36.6 0 002.55 5.84c1.63 3 4.34 8 9.26 11 5.63 3.42 11.65 2.68 15.23 2.23 8.74-1.07 14.72-5.6 19.71-9.38 3.75-2.85 6.23-5.55 11.19-11a56.17 56.17 0 0010.38-15c.6-1.31 2.68-5.9 1.62-6.65-.27-.19-.62-.06-1.24.12a26.46 26.46 0 01-5.66.81 7.57 7.57 0 01-4.66-.81c-1.45-.93-1.31-1.91-3.11-3.17a12.47 12.47 0 00-3.05-1.37c-3.15-1.15-3.76-.83-5.41-1.8a5.29 5.29 0 01-2.79-2.86 4.84 4.84 0 011.36-4.54c.33-.33 1.33-1.25 5.6-1.68 4.85-.49 7.28-.73 9.7.19.91.35 2.74 1.18 4.41.43a3.35 3.35 0 00.87-.56c4-3.42 5.47-11.68 5.47-11.68.08-.48.3-1.75.44-3.55.26-3.77.66-8.84-1.48-14.58z"
        />
      </g>
      <g className={styles.cloudTwo}>
        <path
          d="M1363.92 499.23a27.32 27.32 0 00-12.28-2.73 11.77 11.77 0 00-6.31 2.05c-1.06.76-2.28 1.63-2.55 3.07-.43 2.2 1.79 3.43 1.36 5.28-.36 1.54-2.19 2.08-3.75 2.9-5.11 2.68-7.3 8.26-8.87 12.28-1.45 3.7-2.68 6.85-1.71 10.75a14.37 14.37 0 005.8 8c5.13 3.62 10.9 2.89 13.48 2.56 1.88-.24 8.79-1.12 13.64-6.31 2.33-2.49 3.64-5.4 5.46-5.11 1.31.2 2 1.92 2.38 2.72 1.38 2.81 5 6.18 15 8.36a41.64 41.64 0 0014-.68 36.91 36.91 0 006.31-2c3.33-1.43 5.9-2.53 7.84-5.11a13.54 13.54 0 002.39-6.14 23.1 23.1 0 00-.68-10.75c-2.13-7.63-5.72-20.48-15.18-24.22-12.57-5-23.69 10.77-36.33 5.08z"
          fill="#e6d9ad"
        />
        <path
          className={styles.light}
          d="M1422.2 500.72a20.69 20.69 0 00-7.41-10.27 23.94 23.94 0 00-8.32-4 22.21 22.21 0 00-12-.2c-2.67.79-4.85 2.14-8.58 2.15-.72 0-1.33-.05-2.6 0a28.21 28.21 0 00-6.11.78c-2.42.63-6 1.57-8.26 4.55a56.58 56.58 0 00-4 5.72c-.11.22-.46 1-1 1s-.94-.44-1.2-.76l-6 7.89-2.29 5.34h-1.65l-.26-3.81 1.78-4.71 4.36-7.3a22.77 22.77 0 00-4.65-1 13.12 13.12 0 00-8.06 1.56c-1.19.71-2.91 1.73-3.45 3.77a5.78 5.78 0 00.52 4l.91 3.57a17.17 17.17 0 00-6.54 23.11s1.8 3.81 10.77 3.74a61.38 61.38 0 0015.8-2.14l4.87-1.89-.39-1.43-.06-2.21s.39-1.62 1.17-1.82 4.22.91 4.22.91a37.38 37.38 0 008.58 7.61 26 26 0 007.09 3.51c6.16 1.82 11.41.29 17.55-1.5a39.78 39.78 0 008.19-4 2.72 2.72 0 00.65-.52c1.4-1.62-.48-4.08-.58-7.28-.09-2.86 1.28-5.08 3.38-8.45s2.66-2.87 3.64-5c2-4.25.53-9-.07-10.92z"
        />
      </g>
    </g>
  </svg>
)

export default Clouds
