import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './coding-experience.module.css'

export const CodingExperience = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "code-canvas.png"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h2 className={styles.heading}>Coding Has Never Been More Fun</h2>
          <p className={styles.description}>
            Inspired by the popular kid&apos;s coding platform, Scratch, we
            created our own drag n&apos; drop language that is colorful and easy
            to learn. Built to teach key terms and concepts found in real
            programming languages, our Code Canvas is a perfect entry point for
            beginners or early coders.
          </p>
          <p className={styles.description}>
            If your kid is familiar with Scratch, Lego Boost, or MakeCode,
            they&apos;ll be right at home with this learn-to-code course. If
            not, this is a great place to start.
          </p>
        </header>
        <div className={styles.uiWrapper}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            className={styles.picture}
          />
        </div>
        <div className={styles.features}>
          <div className={styles.featureWrapper}>
            <div className={styles.feature}>
              <img
                className={styles.featureImage}
                src="/images/icon-rank.svg"
                alt=""
              />
              <h4 className={styles.featureTitle}>Module-based Learning</h4>
              <p className={styles.featureDescription}>
                Each chapter is a module that introduces new coding concepts. As
                the story develops so do their skills.
              </p>
            </div>
            <div className={styles.feature}>
              <img
                className={styles.featureImage}
                src="/images/icon-puzzle.svg"
                alt=""
              />
              <h4 className={styles.featureTitle}>Over 150 Challenges</h4>
              <p className={styles.featureDescription}>
                So many challenges to foster your child&apos;s progress. We keep
                it fun to keep them engaged.
              </p>
            </div>
            <div className={styles.feature}>
              <img
                className={styles.featureImage}
                src="/images/icon-power.svg"
                alt=""
              />
              <h4 className={styles.featureTitle}>Countless Hours of Play</h4>
              <p className={styles.featureDescription}>
                There is always something to solve, make, and learn. Keep your
                child&apos;s screen time educational and fun.
              </p>
            </div>
          </div>
        </div>
      </div>
      <img className={styles.malbot} src="/images/malbot.svg" alt="" />
    </section>
  )
}
